const Constants = {
    SW_URL: "/root-service-worker.js",
    GLOBAL_HANDLER_DEFINITIONS: "global-handler-definitions",
    GLOBAL_PERMISSIONS_OVERRIDES: "global-permission-overrides",
    GLOBAL_FEATURES_OVERRIDES: "global-feature-overrides",
    GLOBAL_CONFIG_OVERRIDES: "global-config-overrides",
    GLOBAL_MOCK_WORKFLOW_NOTIFICATIONS: "global-mock-workflow-notifications",
    SESSION_USER_STATE: "userState",
    SESSION_EMAIL_ADDRESS: "emailAddress",
    SESSION_ENVIRONMENT: "environment",
    PAYROLL_QUERYPARAMS: "queryStringParameters",
  };
  
  export { Constants };
  
  export const DB_NAME = "keyval-store";
  export const STORED_VALUE_NAME = "keyval";
  export const QUERY_PATTERN = "-reactQuery";