import { DB_NAME, STORED_VALUE_NAME } from "../constants";
import { createLogger } from "@sage/sbc.core.utils.ui";

export const deleteIndexDbKeys = (pattern: string) => {
  const logger = createLogger();
  try {
    const db = window.indexedDB.open(DB_NAME);

    db.onupgradeneeded = (event) => {
      const database = (event.target as IDBOpenDBRequest).result;

      // istanbul ignore else
      if (!database.objectStoreNames.contains(STORED_VALUE_NAME)) {
        database.createObjectStore(STORED_VALUE_NAME);
      }
    };

    db.onsuccess = (event) => {
      const database = (event.target as IDBOpenDBRequest).result;

      const transaction = database.transaction(STORED_VALUE_NAME, "readwrite");
      const objectStore = transaction.objectStore(STORED_VALUE_NAME);
      // Open a cursor to iterate through all keys
      const request = objectStore.openCursor();

      request.onsuccess = (e) => {
        const cursor = (e.target as IDBRequest).result;

        if (cursor) {
          // If the key contains the pattern, delete it
          if (cursor.key.includes(pattern)) {
            objectStore.delete(cursor.key);
          }
          // Move to the next key
          cursor.continue();
        }
      };

      transaction.oncomplete = () => {
        logger.log(`Keys containing "${pattern}" deleted successfully.`);
      };
    };
  } catch (error) {
    console.error(`General error: ${error}`);
  }
};

export const clearDbError = () => {
  // remove database if it's in an error state without the needed object store
  return new Promise((resolve: (value?: unknown) => void, reject) => {
    const db = window.indexedDB.open(DB_NAME);

    db.onsuccess = (event) => {
      const database = (event.target as IDBOpenDBRequest).result;
      database.close();
      // istanbul ignore else
      if (!database.objectStoreNames.contains(STORED_VALUE_NAME)) {
        const req = window.indexedDB.deleteDatabase(DB_NAME);
        req.onsuccess = () => {
          resolve();
        };
        // istanbul ignore next
        req.onerror = (err) => {
          reject(err);
        };
      } else {
        resolve();
      }
    };

    // istanbul ignore next
    db.onerror = (err) => reject(err);
  });
};